import React from 'react'
import { Flex, InfoSection, Header } from 'ui/acro'

const IE = () => (
  <Flex justifyContent='center' mx={[null, null, 'auto']}>
    <InfoSection
      headline={"Let's move differently"}
      body='For an optimized Alo Moves experience, please use one of our supported browsers'
      center
    >
      <Header
        as='a'
        href='https://www.google.com/chrome'
        target='_blank'
        color='grey5'
        alignSelf={['stretch', null, 'start']}
      >
        Chrome
      </Header>
      <Header
        as='a'
        href='https://www.mozilla.org/en-US/firefox/new/'
        target='_blank'
        color='grey5'
        alignSelf={['stretch', null, 'start']}
      >
        Firefox
      </Header>
      <Header
        as='a'
        href='https://support.apple.com/downloads/safari'
        target='_blank'
        color='grey5'
        alignSelf={['stretch', null, 'start']}
      >
        Safari
      </Header>
      <Header
        as='a'
        href='https://www.microsoft.com/en-us/windows/microsoft-edge'
        target='_blank'
        color='grey5'
        alignSelf={['stretch', null, 'start']}
      >
        Edge
      </Header>
    </InfoSection>
  </Flex>
)

export default IE
